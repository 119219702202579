import React, { useEffect, useState } from "react";
import {paymentSuccess} from '../actions/paymentActions';
import { useDispatch } from 'react-redux'
import Container from "@material-ui/core/Container";

import 'react-toastify/dist/ReactToastify.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
 

import {logout} from '../actions/authActions';

import Typography from "@material-ui/core/Typography";

import {  useLocation  } from "react-router-dom";
import TotalPaypal from "../components/modals/TotalPaypal";
import styles from './OApp.module.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}



export default function Paynowcallback(props) {
 
  const [dopen_ref, setDopen_ref]= React.useState(true);
     
  const {  width } = useWindowDimensions();
    
        
    const dispatch = useDispatch()
    const windowUrl = window.location.search;
 
    

    var total_price = 0;
    var subtotal_price = 0;
    var admin_price = 0;
    
    total_price = subtotal_price + admin_price
  
  
    console.log(props, 'props');
    
    
    

    
    
  var pureText = windowUrl;
   
  let query = useQuery();

  pureText=query.get("name")
  var temp = sessionStorage.getItem('mydata');
  var viewName = JSON.parse(temp); 
  var orderid = sessionStorage.getItem('data.orderIDInLocalStorage')  
  
  var dateNow = new Date();

  useEffect(() => {

    if (viewName=='' || viewName==null) {
    
    confirmAlert({
      customUI: ({ onClose }) => {
      return (
      <div class={styles.body}>
      <h1>Message</h1>
      <p>Expired. Please login</p>
      <div>{JSON.stringify("Ref : " + sessionStorage.getItem('data.orderIDInLocalStorage'))}</div>
      
      <button class={styles.button} 
      onClick={() => {
      dispatch(logout());
      
      }}
      >
        Login</button>
      </div>
      );
      }
      });  
      } 
else
{
  
  {

    if (dopen_ref)
    {
      setDopen_ref(false);
      var gateway = sessionStorage.getItem('admin_price')
      dispatch(paymentSuccess(viewName, "Paynow :"+ sessionStorage.getItem('data.orderIDInLocalStorage')  ))
      
      var shop = sessionStorage.getItem('shop')  
      
      if (width > 500)
      {
       confirmAlert({
         customUI: ({ onClose }) => {
         return (
         <div class={styles.body}>
         <h1>Message - {shop}</h1>
         <div style={{textAlign: 'center' , fontWeight:"bold" , }}>{('Paynow ReferenceID : ' + sessionStorage.getItem('data.orderIDInLocalStorage')+ ' - Successful !')}</div>
         <div>{JSON.stringify("Comfirmation Email will be send to you soon.")}</div>
         <div>{JSON.stringify("If you did not receive email within one day, pls call us.")}</div>
         <p></p>
         <div id="transition-modal-title"><TotalPaypal data={viewName} /></div>

          
         <button class={styles.button} 
         onClick={() => {
         dispatch(logout());
         onClose();
         }}
         >
           Logout</button>
         </div>
         );
         }
         });  
         } 
      if (width <= 500)
      {
      confirmAlert({
      customUI: ({ onClose }) => {
      return (
      <div>
        <h1>Message</h1>
        <p>Payment successful</p>
          <div>{JSON.stringify('Ref: ' + sessionStorage.getItem('data.orderIDInLocalStorage'))}</div>
          <div>Comfirmation Email will be</div>
          <div>send to you soon.</div>
          <div>If you did not receive email </div>        
          <div>within one day, pls call us.</div>        
        <button class={styles.button} 
        onClick={() => {
          dispatch(logout());
          onClose();
        }}
        >
          Logout</button>
      </div>
      );
      }
      });  
      }
    
    }


  
 
    }
}
 


   

  
/*
  if ( pureText == orderid )
  {
  dispatch(paymentSuccess(viewName, "Paynow :"+ orderid ))
  {
    confirmAlert({
      customUI: ({ onClose }) => {
      return (
      <div class={styles.body}>
      <h1>Message</h1>
      <p>If payment not received within 24hr, the renewal will be cancel.</p>
     
      <button class={styles.button} 
      onClick={() => {
      window.location.replace('/')
      onClose();
      }}
      >
        Continue</button>
      
      <button class={styles.button} 
      onClick={() => {
      dispatch(logout());
      onClose();
      }}
      >
        Logout</button>
      </div>
      );
      }
      });  
      } 
  
}
else
  {
    confirmAlert({
      customUI: ({ onClose }) => {
      return (
      <div class={styles.body}>
      <h1>Message</h1>
      <p>No Data. Pls login</p>
     
      <button class={styles.button} 
      onClick={() => {
      //dispatch(logout());
      window.location.replace('/login')
      }}
      >
        Login</button>
      </div>
      );
      }
      });  
      } 
*/
}, []);

        return (





            <div>

          <Container component="main" maxWidth="xs">
     
          <Typography component="h1" variant="h5">
            
          </Typography>


          </Container>



          <Container>

 
  {/*
          
  <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper"
  alignItems="center"
  justifyContent="center"
  ></Box>

<div>
<Typography component="h1" variant="h6">
<div style={{textAlign: 'center'}}> Status : Successful</div>
<div>{JSON.stringify("storage : " + localStorage.getItem('data.orderIDInLocalStorage'))}</div>
Ref number: {pureText}      
</Typography>
<div id="transition-modal-title"><TotalPaypal data={viewName} /></div>
</div>
  */}
  
</Container>
</div>
)
    
}



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}



