import { IS_LOADING} from "./types/loadingTypes";

const initialState = {
    is_loading: 1,
    
};

export default function(state = initialState, action) {
  switch (action.type) {
    case IS_LOADING:
        console.log(initialState)

      return {
        ...state,
        is_loading: action.payload
      };

    
    default:
      return state;
  }
}
