import {PAYPAL_PAYMENT_SUCCESS } from "./types/paymentTypes";

const initialState = {
    paypal_payment_success: [],
    
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PAYPAL_PAYMENT_SUCCESS:
      console.log('payment')
      return {
       
        ...state,
        paypal_payment_success: action.payload
      };

    
    default:
      return state;
  }
}
