import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import Container from "@material-ui/core/Container";
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {  useLocation  } from "react-router-dom";
import Paynow from "../Paynowcall";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export default function PaynowCart(props) {
 
  const [dopen_ref, setDopen_ref]= React.useState(true);
  const {  width } = useWindowDimensions();
  const dispatch = useDispatch()
  const windowUrl = window.location.search;
 
  
  var pureText = windowUrl;
  let query = useQuery();
  pureText=query.get("name")
  var temp = sessionStorage.getItem('mydata');
  var viewName = JSON.parse(temp); 
  viewName.orderID = sessionStorage.getItem('data.orderIDInLocalStorage')  

  
  
        return (





            <div>

          <Container component="main" maxWidth="xs">
     
     {/*
          <Typography component="h1" variant="h5">
          <div style={{textAlign: 'center' , fontWeight:"bold" }}> Ref: {sessionStorage.getItem('data.orderIDInLocalStorage')}</div>
          <div id="transition-modal-title"><TotalPaypal data={viewName} /></div>
          </Typography>
     */}
          <Paynow/>

          </Container>


</div>
)
    
}



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}



