import React, { useState, useEffect } from "react";
import Avatar from "../../../node_modules/@material-ui/core/Avatar";
import Button from "../../../node_modules/@material-ui/core/Button";
import CssBaseline from "../../../node_modules/@material-ui/core/CssBaseline";
import TextField from "../../../node_modules/@material-ui/core/TextField";
import Link from "../../../node_modules/@material-ui/core/Link";
import Grid from ".../../../node_modules/@material-ui/core/Grid";
import Typography from "../../../node_modules/@material-ui/core/Typography";
import { makeStyles } from "../../../node_modules/@material-ui/core/styles";
import Container from "../../../node_modules/@material-ui/core/Container";
import "react-toastify/dist/ReactToastify.css";
import { register } from "../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import history from '../../history';
import {  toast } from 'react-toastify';
import {useHistory} from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowBack from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

export default function LoginPage() {
  const [form, setForm] = useState({
    username: "",
    alias_username: "",
    sms_handphone: "",
    password: "",
    password_confirmation: "",
    email: ""
  });
  const dispatch = useDispatch()
const authData = useSelector(state => state.auth.register)
const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

const [isAuthenticate, setIsAuthenticate] = useState(false);

useEffect(() => {


  
   if(authData.message == "Credentials Correct"){
    
    var now = new Date();
    var minutes = 5;
    now.setTime(now.getTime() + (minutes * 60 * 1000));
    document.cookie = `otp_number=${authData.number}; expires= ${now.toUTCString()}`


    setIsAuthenticate(true);
  }
  
  }, [authData]);

  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(1),
      height: theme.spacing(9),
      width: theme.spacing(9),
      
    },
    
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    }
  }));

  function validate() {
    if (
      form.username == "" ||
      form.alias_username == "" ||
      form.username == "" ||
      form.sms_handphone == "" ||
      form.password == "" ||
      form.password_confirmation == "" ||
      form.email == ""
    ) {
      toast.error("Please input all fields");
      return false;
    } else if (form.password != form.password_confirmation) {
      toast.error("Confirm Password Does Not Match!");
      return false;
    }

    return true
  }

  function handleChange(event) {
    const value = event.target.value;
    setForm({ ...form, [event.target.name]: value });
  }

  function submitForm() {
    const isValidate = validate();

    if (isValidate == true) {
      dispatch(register(form))
    }
  }

  const classes = useStyles();

  if(isAuthenticate){
    return <Redirect to='/otp' />;

  }
  if(isAuthenticated){
    history.push('/')
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
     
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          onChange={handleChange}
        />

<TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="alias"
          label="PP/NO or NRIC"
          name="alias_username"
          autoComplete="alias_username"
          autoFocus
          onChange={handleChange}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password_confirmation"
          label="Confirm Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address "
          name="email"
          autoComplete="email"
          autoFocus
          onChange={handleChange}
        />
    
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="mobile_number"
          label="Mobile Number (eg. +6591234567)"
          name="sms_handphone"
          autoComplete="Mobile Number"
          type="text"
          autoFocus
          onChange={handleChange}
        />

        <Button
          type="text"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={submitForm}
        >
          Sign Up
        </Button>
        <Grid container>
          <Grid item xs>
          
          </Grid>
          <Grid item>
         
          
          <IconButton
            size="small"
            color="primary"
            onClick={() => history.goBack()}
          >
          <ArrowBack /></IconButton>
          <Button size="small" style={{textTransform: 'none'}} color="primary" onClick={() => history.goBack()}>Go Back</Button>
          
          
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
