import {PAYPAL_PAYMENT_SUCCESS} from '../reducers/types/paymentTypes';
import axios from 'axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken'
import { trackPromise } from 'react-promise-tracker'

const auth = jwt.decode(localStorage.jwtToken)


const apiUrl_backup = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-gth/public/api';
const apiUrl = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-gth/public/api';


export const paymentSuccess = (data ,order_id) => async dispatch =>{

    var mycount 

    var res
    var test = 'y';
    var i;
    try {
      for (i = 0; i < 5; i++) {
        //  toast.success('Server...')
          res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,   test}))
          
          if(res.data.message === "testing : ") 
            {
                toast.error('Please wait')
                break;
            }    
          
          
      }
      } catch (error){}   
     
    try{

        var gateway_fee=sessionStorage.getItem('admin_price')  
        var subtotal_price=sessionStorage.getItem('subtotal_price')  
        var total_price=sessionStorage.getItem('total_price')  
        var username=sessionStorage.getItem('username')  
    
     
        
     
     
           
        mycount = 0
    
     
               
       
          //  toast.success('Connecting Server ...'+encryptedStr)
      
 
       //            toast.success('Update ...')
                   for (i = 0; i < 5; i++) {
                   res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
                   console.log(res.data, 'suc')
                   if(res.data.message === "Payment Successful") break;
                   if (mycount > 3) 
                       if(res.data.message !== "Payment Successful") break;
                   mycount = mycount + 1 
                   }
                   
                   if(res.data.actual_count !== res.data.send_count )
                   {
                       
                           for (i = 0; i < 5; i++) {
                               toast.success('Update ...')
                               res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
                               console.log(res.data, 'suc')
                               if(res.data.message === "Payment Successful") break;
                               if (mycount > 3) 
                                   if(res.data.message !== "Payment Successful") break;
                               mycount = mycount + 1 
                               
                           }
                    
                           
               
                   }
                   else
                   toast.success('match')
                  

            if(res.data.message === "Payment Successful"){

   //             toast.success('Payment is processing !')
                
                dispatch({
                    type: PAYPAL_PAYMENT_SUCCESS,
                    payload: res.data
                 });
            }
            else {
           //     toast.error(res.data.message)
                dispatch({
                    type: PAYPAL_PAYMENT_SUCCESS,
                    payload: res.data
                 });
            }
       
        } catch (error){

            

    //        toast.error("Updating - Updating ");


           try{

             gateway_fee=sessionStorage.getItem('admin_price')  
             subtotal_price=sessionStorage.getItem('subtotal_price')  
             total_price=sessionStorage.getItem('total_price')  
             username=sessionStorage.getItem('username')  
    
            mycount = 0
        
            
            
          //      toast.success('Payment Updating .. Now')
                for (i = 0; i < 5; i++) {
                    toast.success('Update ...')
                    res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
                    console.log(res.data, 'suc')
                    if(res.data.message === "Payment Successful") break;
                    if (mycount > 3) 
                        if(res.data.message !== "Payment Successful") break;
                    mycount = mycount + 1 
                    
                }      
    
                if(res.data.actual_count !== res.data.send_count )
                {
                    
                        for (i = 0; i < 5; i++) {
                            toast.success('Update ...')
                            res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
                            console.log(res.data, 'suc')
                            if(res.data.message === "Payment Successful") break;
                            if (mycount > 3) 
                                if(res.data.message !== "Payment Successful") break;
                            mycount = mycount + 1 
                            
                        }
                 
                        
            
                }
                else
                toast.success('match')
                if(res.data.message === "Payment Successful"){
    
                    toast.success('Payment is processing !!')
                    dispatch({
                        type: PAYPAL_PAYMENT_SUCCESS,
                        payload: res.data
                     });
                }
                else {
                    dispatch({
                        type: PAYPAL_PAYMENT_SUCCESS,
                        payload: res.data
                     });
                }
           
            } catch (error){
    
                
    
          //      toast.error("Internal Server Error");
                var res
                var test = 'y';
                var i;
                try {
                  for (i = 0; i < 5; i++) {
                    //  toast.success('Server...')
                      res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,   test}))
                      
                      if(res.data.message === "testing : ") 
                        {
                //            toast.error('Please wait')
                            break;
                        }    
                      
                      
                  }
                  } catch (error){}   
            }
    
       //     toast.success('Payment Updating .. Now')
            for (i = 0; i < 5; i++) {
                toast.success('Update ...')
                res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
                console.log(res.data, 'suc')
                if(res.data.message === "Payment Successful") break;
                if (mycount > 3) 
                    if(res.data.message !== "Payment Successful") break;
                mycount = mycount + 1 
                
            }      

            if(res.data.actual_count !== res.data.send_count )
            {
                
                    for (i = 0; i < 5; i++) {
                        toast.success('Update ...')
                        res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
                        console.log(res.data, 'suc')
                        if(res.data.message === "Payment Successful") break;
                        if (mycount > 3) 
                            if(res.data.message !== "Payment Successful") break;
                        mycount = mycount + 1 
                        
                    }
             
                    
        
            }
            else
            toast.success('match')
            if(res.data.message === "Payment Successful"){

                toast.success('Payment is processing !!')
                dispatch({
                    type: PAYPAL_PAYMENT_SUCCESS,
                    payload: res.data
                 });
            }
            //toast.success('Done ...')

    
        }


}