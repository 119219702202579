import React, { useEffect, useState } from "react";

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch} from 'react-redux'
import {logout} from '../actions/authActions';

import jwt from 'jsonwebtoken';
import history from '../history';


const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },


  mytitle: {
    
    marginLeft: theme.spacing(120),
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    /*
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    */
    marginLeft: -drawerWidth,
    marginTop: theme.spacing(0),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const auth = jwt.decode(localStorage.jwtToken) || null ;

var progname = "Online-PDMS"

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}



function Navlogouts(props) {
  
  const dispatch = useDispatch()

  const handleclick = () => {
    
        
    var mytable = document.getElementById('mytable')
 
    if  (mytable!=null)
    { 
      for(var i = mytable.rows.length - 1; i >= 0; i--)
      {
          mytable.deleteRow(i);
      }
    }

    
    
    localStorage.removeItem("mylogin")  
                  
  
    var shop = sessionStorage.getItem('shop')  
    var shopname=""
   
      shopname = 'Session Expired'
      window.location.replace('/')
      localStorage.removeItem("jwtToken")  
      sessionStorage.setItem('Logout', 'done');
      
    dispatch(logout())


  };


  

  return (

    <div style={{ width: '87%' }}>
      
    <Box display="flex" p={1} >
    <Box p={1} flexGrow={1} >
    </Box>
    <Box p={1} bgcolor="">
    <Typography variant="h6" noWrap>
     

       <Button
              fullWidth
              variant="contained"
              color="primary"
        
                  type=""
                  fullWidth
                  onClick={ handleclick}
              >   
              <Typography  noWrap>
              Logout</Typography>
        </Button>  
          
     </Typography>
    </Box>
    </Box>
    </div>
  )
  
}






export default function SideBar(props) {

  const { height, width } = useWindowDimensions();

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [sw, setSw] = React.useState(false);

  const [once, setOnce] = React.useState(false);
  const [twice, setTwice] = React.useState(false);

  const dispatch = useDispatch()
  const handleDrawerOpen = () => {
  
    if (width < 1350) {
      progname = "PDMS"
    
    }
    else
    {
      progname = "Online-PDMS"
      setOpen(true);
      setSw(true);
       
    }
  };


  
  const handleDrawerClose = () => {
   
    setSw(false);
  
    if (width < 800) {

    setOpen(false);
    }

  

  };


  const handleclick = () => {
    
  };

  const logOut = () => {


    
    var mytable = document.getElementById('mytable')
 
    if  (mytable!=null)
    { 
      for(var i = mytable.rows.length - 1; i >= 0; i--)
      {
          mytable.deleteRow(i);
      }
    }

    
    dispatch(logout())

  }

  
  const [sidebarList, setSidebarList] = useState([
    {list_name: "Ticket List", link : "/"}
  ])

  const [pickedRoute, setPickedRoute] =useState(0)



  function changeRoute(route, index){


    setPickedRoute(index)
    history.push(route);

  }


  
  
  

  if (width <= 1350) {
    if (once === false )
      {setOpen(false);
      setOnce(true);
      progname="PDMS"
      }
    }

if (width > 1350) {
  if (once === true )
  {
    if (twice===false)
     {
        setSw(false);
        setOpen(true);
        setOnce(false);
        progname = "Online-PDMS"
     }
  }
}
   

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          
          
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          
          
          
          
          
          <Typography variant="h6">
           {progname}
          </Typography>
          
          <Navlogouts/>
          
          
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >

    
        <div className={classes.drawerHeader}>
          {          /* Control the drawer close icon delpend of the width, sw mean small width        
                        icon will only appear in small width                                      
          */}
          <div>
          {(() => {
          if (sw) {
          return <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>;
          } else {
          return ;
          }
          })()}
          </div>
     
        </div>
        
        <Divider />
        <List>
        <AccountCircleIcon style={{fontSize: '40px'}} />
        <p style={{fontWeight: 'bold'}}>{auth ? auth.username : null}</p>

           {sidebarList.map((text, index) => (
             <ListItem button key={text.list_name} onClick={() => changeRoute(text.link, index)}  style={index === pickedRoute ?{ backgroundColor: '#3f51b5', color: 'white'} : null }>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text.list_name} />
            </ListItem>
          ))} 
        </List>



        <Divider />
        <List>
          {/* {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))} */}

                <ListItem button key={"Logout"} onClick={logOut}>
              <ListItemIcon><ExitToAppIcon/></ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        
        {props.children}
      </main>
    </div>
  );
}