import React, { useState, useEffect }  from "react";
import "./App.css";
import { Redirect, Router, Route, Switch } from "react-router-dom";



import LoginPageDranix from "./components/auth/LoginPageDranix";



import RegisterPage from "./components/auth/RegisterPage";
import OtpPage from "./components/auth/OtpPage";
import SideBar from "./components/SideBar";
import TicketList from "./components/tickets/TicketList";
import { Provider } from "react-redux";
import store from './store';
import { toast } from 'react-toastify';
import { setIsAuthenticated } from "./actions/authActions";
import jwt from 'jsonwebtoken';
import { ProtectedRoute } from "./routes/protectedRoutes";
import LoadingScreen from "./components/loadingScreen/loadingScreen"
import history from './history';

import Resetpassword from "./components/auth/Resetpassword";
import Trigger from "./components/auth/Trigger";
import TriggerSet from "./components/auth/TriggerSet";

import Maintenance from "./components/auth/Maintenance";
import {Helmet} from "react-helmet";
import moment from "moment";

import Paynowcallback from './Paynowcallback';
import Paynow from './Paynowcall';
import PaynowCart from './PaynowCart';

import { isExpired, decodeToken } from "react-jwt";

toast.configure({
  autoClose: 4000,
  draggable: false,
  //etc you get the idea
});


function App1(props) {
 
 
  const isLoading = store.getState()

  
  const [loading, setLoading] =useState(1)

  const [down, setDown] =useState(99)

  var date_create;
  
  function isValid(date, h1, m1, h2, m2) {
    var h = date.getHours();
    var m = date.getMinutes();
    return (h1 < h || h1 == h && m1 <= m) && (h < h2 || h == h2 && m <= m2);
  }
  
  

  
  var shopname = sessionStorage.getItem('shop')  
  var isactive

  if (shopname === 'Teck Hing Pawnshop (Ngee Kee)'  )
  {
  isactive = true
  }

  const token = localStorage.jwtToken;
  var decodedToken=jwt.decode(token, {complete: true});
  var dateNow = new Date();
  
  const myDecodedToken = decodeToken(token);
  const isMyTokenExpired = isExpired(token);

  useEffect(()=>{

  if (isMyTokenExpired)
    {
      localStorage.removeItem("jwtToken")  
      sessionStorage.setItem('Logout', 'done');
 
      
    }
  
  
  

 // date_create = moment().format("HHmm");
 //  console.log(date_create)
 // console.log('The website is under maintenance from 11.45pm to 12.15am')

  if (isValid(new Date(), 23, 39, 23, 59) || isValid(new Date(), 0, 0, 0, 15) ) 
  
  {
  // setDown(0);
   setDown(1);
  }
  else
  {
    setDown(0);
  }

  
  

  setLoading(isLoading.isLoading.is_loading)
  console.log(isLoading.isLoading.is_loading,'load')


},[isLoading])


  if(localStorage.jwtToken){

    store.dispatch(setIsAuthenticated(jwt.decode(localStorage.jwtToken)))
  }

  
    if (performance.navigation.type == 1) {
      window.location.replace('/')
    } 

// down mean under maintenance
    
if (down==1)
 {

  return (
    <Provider store={store}>
    <div className="App">
    <Helmet>
                <meta charSet="utf-8" />
                <title>Online PDMS</title>
    </Helmet>
    <LoadingScreen /> 
    <Router history={history}>
    <Switch>
    <Route exact path="/" component={Maintenance} />
    <Route exact path="/login" component={Maintenance} />
    <Route exact path="/register" component={Maintenance} />
    <Route exact path="/resetpassword" component={Resetpassword} />
    <Route exact path="/otp" component={Maintenance} />

    </Switch>
    </Router>
    </div>
    </Provider>
  );
}
if (down==0)
{
  return (
  <Provider store={store}>
  <div className="App">
  <Helmet>
              <meta charSet="utf-8" />
              <title>Online PDMS</title>
    
  </Helmet>
  <LoadingScreen /> 
  <Router history={history}>
  


  {!localStorage.jwtToken ? 

<Switch>

  <Route exact path="/dranix" component={LoginPageDranix} />
  <Route exact path="/login" component={LoginPageDranix} />
  
  <Route exact path="/register" component={RegisterPage} />
 
  <Route exact path="/paynowcallback" component={Paynowcallback}/>
  <Route exact path="/paynowCart" component={PaynowCart}/>

  <Route exact path="/paynow" component={Paynow}/>

  <Route exact path="/otp" component={OtpPage} />
  
  <Route exact path="/resetpassword" component={Resetpassword} />
  <Route exact path="/trigger" component={Trigger} />
  <Route exact path="/triggerSet" component={TriggerSet} />
  <Route path="/" component={LoginPageDranix} />
  

</Switch>
:

!isMyTokenExpired ? 


 <SideBar>
     <ProtectedRoute path="/" component={TicketList} />
</SideBar>
:
<Route path="/" component={LoginPageDranix} />



}


  
  

  </Router>
  </div>
  </Provider>
);
}
if (down==99)
{
  return (
  <Provider store={store}>
  <div className="App">
  <Helmet>
              <meta charSet="utf-8" />
              <title>Online PDMS</title>
    
  </Helmet>
  
  </div>
  </Provider>
);
}


}

export default App1;
