import {combineReducers} from 'redux';
import authReducers from './authReducer';
import ticketReducers from './ticketReducer'
import loadingReducers from './loadingReducer'
import paymentReducers from './paymentReducer'

export default combineReducers({
    auth: authReducers,
    ticket:ticketReducers,
    isLoading:loadingReducers,
    payments:paymentReducers
})