import React from 'react';
import {paymentSuccess} from '../../actions/paymentActions';
import { useDispatch } from 'react-redux';
import axios from 'axios';


export default function TotalModal(props) {
  
  
  var subtotal_price = 0;
  
  for (let i = 0; i < props.data.length; i++) {
    let data = props.data[i];
    subtotal_price = subtotal_price + data.interest_payable;
  }
  

 //   subtotal_price='3.33'

 sessionStorage.setItem('admin_price', '0' );
 sessionStorage.setItem('subtotal_price', subtotal_price );
 sessionStorage.setItem('total_price', subtotal_price );




 var myref = localStorage.getItem('data.orderIDInLocalStorage')
if (myref.length!==14)
{
       // 2nd try

      //toast.error(myref);

     //  toast.success("Logging");
      
        ( axios.get("https://dranixmyapp1.herokuapp.com/qr-request") )

 
       .then(response => {
         console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
         console.log(response.data);
         console.log(response.data.ref);
         myref = response.data.ref
       // console.log(response.data.message);
       
       //setFruit(myref);
      // aurl = "https://dranixmyapp1.herokuapp.com/qrcode?total=1&ref="+myref+"&shopcode=_DRA"
      // setFruiturl(aurl);
         var myres = "";

         myres = response.message;
         localStorage.setItem('data.orderIDInLocalStorage' ,myref);
         localStorage.setItem('data.orderIDInLocalStorage-url' ,"https://dranixmyapp1.herokuapp.com");
  
       //  toast.success("API Retrieving Tickets : Ref-"+myref);
   
       })
       .catch(error => {
      
         console.log('"API CALL fail');
         console.log(error);
         
      
       });
   
}

  
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (<div>
    
    
    Int: {formatter.format(subtotal_price)}

    
  </div>);
}
