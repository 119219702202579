import React, { Component } from 'react'
import Maesh from 'maesh-test'
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { useDispatch } from 'react-redux'
import  { encrypt , decrypt } from 'react-crypt-gsm';

import Button from "@material-ui/core/Button";

import 'react-toastify/dist/ReactToastify.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
 

import Typography from "@material-ui/core/Typography";

import PaynowQR from 'paynowqr'
import QRCode from 'qrcode.react';

import moment from "moment";




// Base64 string data


var firstDot = window.location.hostname.indexOf('.');
var domain;
var maindomain;

domain = window.location.href;

maindomain = window.location.origin;



export default class Paynow extends Component {


  
  

    constructor() {
      super();

      
      this.testdata = {
  
          
      api_key: '78891995940fe3f2ef42cd8836e9500d6d0a20dd',
      amount: 1234.12,
      currency: "SGD",
      referenceCode: "my23EFGH",
      gotoUrl: maindomain + '/paynowcallback?name=my23EFGH',
      


      thisUrl: 'https://www.dranixcomputer.com/paynowcallback?name=me42',
     
    }
  
    
  }



    render() {


      const windowUrl = window.location.search;
 
      
      
      var pureText = windowUrl;
      var total_price = this.testdata.amount;
      var encryptedStr ="6000433100"
      let qrcode = new PaynowQR({
        uen:'200410276N',           //Required: UEN of company
        amount : total_price,               //Specify amount of money to pay.
        editable: false,             //Whether or not to allow editing of payment amount. Defaults to false if amount is specified
        expiry: '20201231',         //Set an expiry date for the Paynow QR code (YYYYMMDD). If omitted, defaults to 5 years from current time.
        refNumber: encryptedStr,   //Reference number for Paynow Transaction. Useful if you need to track payments for recouncilation.
        company:  'DRANIX IT-OUTSOURCE PTE LTD'   //Company name to embed in the QR code. Optional.               
      })
      
      const QRstring = qrcode.output();
      
      console.log(JSON.stringify(QRstring))  ;   
 

        return (

            <div>
            <Container>
{/*
            <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper"
            alignItems="center" justifyContent="center"
            >
           <Maesh data={this.testdata} />
            </Box>
*/}         


            <div>{JSON.stringify("Domain : " + domain)}</div>
            <div>{JSON.stringify("Main Domain : " + maindomain)}</div>
            <div>{JSON.stringify("Amount : " + this.testdata.amount)}</div>
            <div>{JSON.stringify("URL : " + this.testdata.gotoUrl)}</div>
            <div>{JSON.stringify("QRCODE : " +QRstring)}</div>
            <div>{JSON.stringify("Api key : " + this.testdata.api_key)}</div>
            <div>
            <p></p>  
            </div>
            </Container>
            </div>
        )
      }
}
    


