import { FETCH_TICKET, FETCH_TODO} from "./types/ticketTypes";

const initialState = {
    ticket: [],
    todo_list: [],
    
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TICKET:
      return {
        ...state,
        ticket: action.payload
      };

      case FETCH_TODO:
        return {
          ...state,
          todo_list: action.payload
        };

    
    default:
      return state;
  }
}
