import React, { useState, useEffect,useRef } from "react";
import logo from "../money.png";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {login} from '../../actions/authActions';
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom';
import history from '../../history';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import styles from './OApp.module.css';
import {  toast } from 'react-toastify';
import Spinner from '../layout/Spinner';
import {logout} from '../../actions/authActions';
import { trackPromise } from 'react-promise-tracker'

sessionStorage.removeItem('TrustcareGroup' )  
axios.interceptors.response.use(undefined, (err) => {
  const { config, message } = err;
  if (!config || !config.retry) {
    return Promise.reject(err);
  }
  // retry while Network timeout or Network Error
  if (!(message.includes("timeout") || message.includes("Network Error"))) {
//    toast.error("err 2")
 //   return Promise.reject(err);
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
     toast.error("Please wait")
      console.log("retry the request", config.url);
      resolve();
    }, config.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => axios(config));
});


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}



function do_once()

  {
     
 
    }
    
  




function dosomething() {
}

// ==========================================================================================================
// URL and authorization header
// ==========================================================================================================
const apiUrl = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-gth/public/api'

function setAuthorizationToken (token){

  
  if(token){
        return {headers:{
            'Authorization': `Bearer ${token}`
        }}
    }
    else {
        return false    
    }


}
const config = setAuthorizationToken("MiA4MzA0In0.Lbs5QaXwinnwsKwpfy3APkO3poUjBljVOOcIk6-XUIQTEST")



// ==========================================================================================================













 function LoginPageDranix(  { props } ) {

  
  const dispatch = useDispatch()

  localStorage.removeItem("token")
  localStorage.removeItem("JWT")
  localStorage.removeItem('jwtToken')

  const [form, setForm] = useState({
    username: "",
    password: "",
    shopname:"",
  });
  const {  width } = useWindowDimensions();


  const [errorText, setErrorText] = useState({ error: false, error_text: "" });
  const [isAuthenticate, setIsAuthenticate] = useState(false);

  const authData = useSelector(state => state.auth.login)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

  const [Loading, setLoading] = useState(false);

  
  const useImageLoaded = () => {
    const [loaded, setLoaded] = useState(false)
    const ref = useRef()
  
    const onLoad = () => {
      do_once()

      setLoaded(true)
    }
  
    useEffect(() => {

      
      if (ref.current && ref.current.complete) {
        onLoad()
       
      }
    })
  
    return [ref, loaded, onLoad]
  }
  
  const [ref, loaded, onLoad] = useImageLoaded()
 
  useEffect(() => {


   
  if(authData.message === "Unauthorized"){
    setErrorText({ error_text: "Incorrect Password Or Username", error: true });


  }
  else if(authData.message === "user is blocked"){
    setErrorText({ error_text: "User is Blocked", error: true });

  }
  else if(authData.message === "User is log in in other device please log out it first!."){
    setErrorText({ error_text: "User is log in on other device please log out it first!.", error: true });

  }
  else if(authData.message === "Credentials Correct"){
    


    var now = new Date();
    var minutes = 5;
    now.setTime(now.getTime() + (minutes * 60 * 1000));
    document.cookie = `otp_number=${authData.number}; expires= ${now.toUTCString()}`

    let shop = sessionStorage.getItem('shop')  

    setIsAuthenticate(true);
  }
  else
  {
  window.localStorage.clear(); //try this to clear all local storage  
  sessionStorage.setItem('shop', 'Dranix Computer')  
  localStorage.setItem('shop', 'Dranix Computer')  

  }
  
  }, [authData]);

  function handleChange(event) {
    const value = event.target.value;

    setForm({ ...form, [event.target.name]: value });
  }

  function handleChangeShop(event) {

    alert('Change Shopname');
    const value = event.target.value;

    setForm({ ...form, [event.target.name]: value });
  }


  function callapi()
  {

    if (form.username == "")
    {
    }
    else
    {
     
  //  toast.error('Your Request is submitted.');
  //  toast.error('The Password will be reset within 24hrs.');
    
    var username = form.username;
    var password = form.password;
    
    //axios.post(`${apiUrl}/dra/forgotpassword`, config)
    //axios.post(`${apiUrl}/dra/forgotpassword`, {username, password}, config )
    axios.post(`${apiUrl}/dra/forgotpassword`, {username, password}, config )
    .then(response => {
      console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
      console.log(response.data);

      setLoading(false);
           
    //  alert('Your Request is submitted. The Password will be reset within 24hrs. Our staff will contact you.')  // onlinePDMS API test
      doOK();

   
    })
    .catch(error => {
      console.log('"API CALL fail');
      console.log(error);
  
      setLoading(false);
      
      alert('Your Request is not successuful. You may have key in the wrong username')  // onlinePDMS API test
    
    });

  }
  
  toast.error('We are processing your request.');  
  
  

  }


  function doOK() {

    if (width < 600)
  
    {
    confirmAlert({
      customUI: ({ onClose }) => {
    return (
  
    <div>
    <h1>Message</h1>
        
        <p>The Password will be reset within 24hrs.</p>
        
        <button class={styles.button} 
              onClick={() => {
                window.location.replace('/');
                onClose();
                dispatch(logout());

              }}
            
              >
                OK</button>
                
        
        </div>
        );
      }
     });  
  
    }
    else
   
    confirmAlert({
      customUI: ({ onClose }) => {
    return (
  
   
    <div class={styles.body}>
    <h1>Message</h1>
        
    <p>The Password will be reset within 24hrs.</p>
    
    <button class={styles.button} 
          onClick={() => {
            window.location.replace('/');
            onClose();
            dispatch(logout());

          }}
        
          >
            OK</button>
            
         
  
        </div>
        );
      }
     });  
  
 //       alert('Your Request is submitted. The Password will be reset within 24hrs. Our staff will contact you.')  // onlinePDMS API test
    }
  
  
  
  function doforgot() {

    

    if (form.username == "")
    {
      alert('No Username');
    }
  

    else
    {

      if (width < 600)

      {
      confirmAlert({
        customUI: ({ onClose }) => {
      return (
    
      <div>
      <h1>Confirm</h1>
      <p>Hi {form.username}.</p>
      <p>Do you want to reset your password ?</p>
      
      <button class={styles.button} 
            onClick={() => {
              setLoading(true);
             
              callapi();
              onClose();
            }}
          
            >
              Yes</button>
              <button class={styles.button}
            onClick={() => {
              dosomething();
              onClose();
            }}
            >
            No
            </button>
           
    
          </div>
          );
        }
       });  

      }
      else
      {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            
          <div class={styles.body}>

           
            <h1>Forgot Password</h1>
            <p>Hi {form.username}. Do you want to reset your password ?</p>
          

            <button class={styles.button} 
            onClick={() => {
              setLoading(true);
              onClose();
              callapi();
            }}
          
            >
              Yes</button>
              <button class={styles.button}
            onClick={() => {
              dosomething();
              onClose();
            }}
            >
            No
            </button>

     
          </div>
          );
        }
        });  
      
      
      }
      
    }


    
  }



  

// when request, can set retry times and retry delay time


  function submitForm() {
    
//    sessionStorage.setItem('shop', 'Dranix Computer')  
//    localStorage.setItem('shop', 'Dranix Computer')  
  
//  sessionStorage.setItem('shop', 'Ban Fook Pawnshop Sembawang')  
//  localStorage.setItem('shop', 'Ban Fook Pawnshop Sembawang')  
var password = "0000";
    var username = "none";
    var mypass = encodeURIComponent(password);
  //  var res =  (axios.get("https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-gth/public/api/dra/login?username="+username+"&password="+mypass+"&shopname="+shopname))
      
      //console.log(res.data, 'login')


 //  dispatch(login(form))



 
    const s1 = form.shopname;
      const s2 = "tesT";
      const s3 = "test";
      var username_test = form.username;
      var username = form.username;
      var password = form.password;
      var shopname = form.shopname;
      var shopname_test = "test";
      var password_test = "test";
      var test = "yes";
      var url = `https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-gth/public/api/dra/login?username=y&test=y`
     //   dispatch(login(form))   
      
axios.get(url, {retry: 3, retryDelay: 3000});

  var shopname = document.getElementById('shopname').value
 
    
  form.shopname= shopname

  localStorage.removeItem('shoptc')  
  
  if (shopname=='Ban Fook Pawnshop Sembawang')
  {
    sessionStorage.setItem('shoptc', 'Ban Fook Pawnshop Sembawang')  
    localStorage.setItem('shoptc', 'Ban Fook Pawnshop Sembawang')  

    sessionStorage.setItem('shop', 'Ban Fook Pawnshop Sembawang')  
    localStorage.setItem('shop', 'Ban Fook Pawnshop Sembawang')  
   
  }
  



  if (shopname=='Ban Fook Pawnshop Woodlands')
  {
    sessionStorage.setItem('shoptc', 'Ban Fook Pawnshop Woodlands')  
    localStorage.setItem('shoptc', 'Ban Fook Pawnshop Woodlands')  
   
    sessionStorage.setItem('shop', 'Ban Fook Pawnshop Woodlands')  
    localStorage.setItem('shop', 'Ban Fook Pawnshop Woodlands')  
   
  }

  if (shopname=='Ban Fook Pawnshop Shop A')
  {
    sessionStorage.setItem('shoptc', 'Ban Fook Pawnshop Shop A')  
    localStorage.setItem('shoptc', 'Ban Fook Pawnshop Shop A')  
   
    sessionStorage.setItem('shop', 'Ban Fook Pawnshop Shop A')  
    localStorage.setItem('shop', 'Ban Fook Pawnshop Shop A')  
   
  }

  if (shopname==='TECK HING Pawnshop Sembawang')
  {
    sessionStorage.setItem('shoptc', 'TECK HING Pawnshop Sembawang')  
    localStorage.setItem('shoptc', 'TECK HING Pawnshop Sembawang')  

    sessionStorage.setItem('shop', 'TECK HING Pawnshop Sembawang')  
    localStorage.setItem('shop', 'TECK HING Pawnshop Sembawang')  
   
  }
  

  if (shopname==='Dranix Pawnshop')
  {
    sessionStorage.setItem('shoptc', 'Dranix Pawnshop')  
    localStorage.setItem('shoptc', 'Dranix Pawnshop')  

    sessionStorage.setItem('shop', 'Dranix Pawnshop')  
    localStorage.setItem('shop', 'Dranix Pawnshop')  
   
  }
  

  if (shopname==='Teck Hing Pawnshop (Ngee Kee)')
  {
    sessionStorage.setItem('shoptc', 'Teck Hing Pawnshop (Ngee Kee)')  
    localStorage.setItem('shoptc', 'Teck Hing Pawnshop (Ngee Kee)')  
   
    sessionStorage.setItem('shop', 'Teck Hing Pawnshop (Ngee Kee)')  
    localStorage.setItem('shop', 'Teck Hing Pawnshop (Ngee Kee)')  
   
  }
  if (shopname==='Trustcare')
  {
    sessionStorage.setItem('shoptc', 'Trustcare')  
    localStorage.setItem('shoptc', 'Trustcare')  
        
    sessionStorage.setItem('shop', 'Trustcare')  
    localStorage.setItem('shop', 'Trustcare')  
    
  }
  if (shopname==='Sembawang Teck Hing')
  {
    
    sessionStorage.setItem('shoptc', 'Sembawang Teck Hing')  
    localStorage.setItem('shoptc', 'Sembawang Teck Hing')  
    

    sessionStorage.setItem('shop', 'Sembawang Teck Hing')  
    localStorage.setItem('shop', 'Sembawang Teck Hing')  
    
  }
 
  if (shopname==='Dranix Computer Shop B')
  {
    
    sessionStorage.setItem('shoptc', 'Dranix Computer Shop B')
    localStorage.setItem('shoptc', 'Dranix Computer Shop B')
    

    sessionStorage.setItem('shop', 'Dranix Computer Shop B')  
    localStorage.setItem('shop', 'Dranix Computer Shop B')  
    
  }
 


  var test =  localStorage.getItem('shoptc')  
  
  if (test == null)
  {
    alert ("Pawnshop Not Selected")
  }
  else
  {
       url = `https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-gth/public/api/dra/login?username=y&test=y`
    
       trackPromise(axios.get(url, {retry: 10, retryDelay: 5000})
          ).then(response => {
    
      //  toast.success("Connected");
        dispatch(login(form))        
       
        })
        .catch(error => {
   
      
          trackPromise(axios.get(url, {retry: 10, retryDelay: 5000})
          ).then(response => {
   //     toast.success("Connection OK");
         
        dispatch(login(form))        
       
        })
        .catch(error => {
   
      
        });
    
   

        });
    
   


  }



    
  }



  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(2),
      height: theme.spacing(10),
      width: theme.spacing(10),
      
    },
    
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    }
  }));

  const classes = useStyles();


  if(isAuthenticated){
    history.push('/')
  }
  if(isAuthenticate){
    return <Redirect to='/otp' />;

  }

    //window.localStorage.clear(); //try this to clear all local storage  
 // sessionStorage.setItem('shop', 'Ban Fook Pawnshop Sembawang')  
 // localStorage.setItem('shop', 'Ban Fook Pawnshop Sembawang')  

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const top100Films = [
    // { title: 'TECK HING Pawnshop Sembawang', year: 1 },
     { title: 'Teck Hing Pawnshop (Ngee Kee)', year: 2 },
   ];
    
var showHideDemo1 = 1

 
// window.localStorage.clear(); //try this to clear all local storage  
//sessionStorage.setItem('shop', 'DranixGroup')  
//localStorage.setItem('shop', 'DranixGroup')  
   

return (
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      
 
    <Avatar color="primary" style={{ height: '160px', width: '120px', padding : '20px'  }} ref={ref} onLoad={onLoad} src={logo} alt="" className={classes.avatar}/>
   
      {!loaded ? <Spinner /> : 
        (<React.Fragment>

   




   
        <Typography component="h1" variant="h5"  style={{ height: '50px', width: '900px',  }}>
        <b>TECK HING ONLINE PAYMENT</b>
  
        </Typography>
      

        <b>Our Online Renewal service will not be available daily from 11.30pm t0 12.30am</b>
  
        
  
  

        <Grid container direction={"column"} spacing={1}>

        <Grid item>

  <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="username"
        label="Username"
        name="username"
        autoFocus
        onChange={handleChange}
      />

</Grid>
</Grid>

<Grid container direction={"column"} spacing={2}>

<Grid item>
           
       <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          error={errorText.error}
          helperText={errorText.error_text}
          onChange={handleChange}
       
        />

</Grid>

<Grid item>

<div>
{showHideDemo1 && 
<Autocomplete
    variant="outlined"
    margin="normal"
  
  id="shopname"
  name="shopname"
      
  options={top100Films}
  getOptionLabel={(option) => option.title}
  fullWidth
  required
       
  renderInput={(params) => <TextField {...params}  label="Name of Pawnshop" variant="outlined" onChange={handleChangeShop}
  />}
  />  
}

</div>
</Grid>

</Grid>
     
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={submitForm}
        >
          Sign In
        </Button>


        <Grid container>
          <Grid item xs = {4}>
            <Link href="#" variant="body2" onClick={() => {
          doforgot();
          
          }}>


              {"Forgot password?"}
            </Link>
          </Grid>
          <Grid item xs = {4} >
               
          </Grid>
          <Grid item xs = {4}>
            <Link href="/register" variant="body2">
              {"Sign Up an Account"}
            </Link>
          </Grid>
        </Grid>


      </React.Fragment>
        )
      }
       
    </div>
 
  </Container>
);
}

export default LoginPageDranix;
