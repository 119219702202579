import {FETCH_TICKET, FETCH_TODO} from '../reducers/types/ticketTypes';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken'
import setAuthorizationToken from './authActions'
import { trackPromise } from 'react-promise-tracker'
import {logout} from '../actions/authActions';


const apiUrl = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-gth/public/api'

const config = setAuthorizationToken(localStorage.jwtToken)
const auth = jwt.decode(localStorage.jwtToken)


axios.interceptors.response.use(undefined, (err) => {
  const { config, message } = err;
  if (!config || !config.retry) {
    return Promise.reject(err);
  }
  // retry while Network timeout or Network Error
  if (!(message.includes("timeout") || message.includes("Network Error"))) {
//    toast.error("err 2")
 //   return Promise.reject(err);
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      toast.error("Please wait")
      console.log("retry the request", config.url);
      resolve();
    }, config.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => axios(config));
});


export const getAllTicket = () => async dispatch =>{

    const {username} = auth

    var myref = "NIL"
    var myres = "";
    var res
    var test = 'y';
    var i;
    try {
      for (i = 0; i < 5; i++) {
        //  toast.success('Server...')
          res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,   test}))
          
          if(res.data.message === "testing : ") 
            {
                toast.error('Please wait')
                break;
            }    
          
          
      }
      } catch (error){}   

      ( axios.get("https://https://dranixmyapp1.herokuapp.com/qr-request") )
    .then(response => {
        console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
        console.log(response.data);
        console.log(response.data.ref);
        myref = response.data.ref
      // console.log(response.data.message);
      
        myres = response.message;
        localStorage.setItem('data.orderIDInLocalStorage' ,myref);
        localStorage.setItem('data.orderIDInLocalStorage-url' ,"https://dranixmyapp1.herokuapp.com");
      
  
  
      })
      .catch(error => {
     
        console.log('"API CALL fail');
        console.log(error);
        
   
        console.log(username);
     ( axios.get("https://dranixmyapp1.herokuapp.com/qr-request") )

    .then(response => {
      console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
      console.log(response.data);
      console.log(response.data.ref);
      myref = response.data.ref
      myres = response.message;
      localStorage.setItem('data.orderIDInLocalStorage' ,myref);
      localStorage.setItem('data.orderIDInLocalStorage-url' ,"https://dranixmyapp1.herokuapp.com");
    

     

    })
    .catch(error => {
   
      console.log('"API CALL fail');
      console.log(error);
      
 
    if (myref.length!==14)
    {
           toast.success("Logging");
          
           ( axios.get("https://dranixmyapp1.herokuapp.com/qr-request") )

     
           .then(response => {
             console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
             console.log(response.data);
             console.log(response.data.ref);
             myref = response.data.ref
             myres = response.message;
             localStorage.setItem('data.orderIDInLocalStorage' ,myref);
             localStorage.setItem('data.orderIDInLocalStorage-url' ,"https://dranixmyapp1.herokuapp.com");
      
       
           })
           .catch(error => {
          
             console.log('"API CALL fail');
             console.log(error);
             
          
           });
       
    }


   
    });

  
  

     
      });
  
    
   
    
 
  
    
    
    try{

      

        
       

       var encryptedStr = localStorage.getItem('data.orderIDInLocalStorage' );
    
     //   toast.success("Tickets : Ref-"+encryptedStr);

        try{

        //  const res1 = await trackPromise(axios.get(`https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/auth/getshopname2?username=`+username, config))
    
       var s1 = `https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-gth/public/api/dra/ticket?username=`+username;
       // toast.success(s1);

            const res = await trackPromise(axios.get(s1, config))
    
            sessionStorage.setItem('username', username );
          
            if(res.data.invalid_token  ){
                console.log(res.data)
                localStorage.removeItem('jwtToken')
                window.location.replace("/login");
    
            }
            else if(res.data.data === "No Data Found"){
                toast.error(res.data.data);
                toast.error(res.data.data);
                return false
    
            }
           
            dispatch({
                type: FETCH_TICKET,
                payload: res.data
             });
            } catch (error){
                
 //             res.send(error.response.status, error.response.data);
              toast.error(error.response.status);
              toast.error(error.response.status);
 
                return "Internal server error"
            }
    


        } catch (error){

      //  toast.error("Read Error");
           sessionStorage.setItem('Logout', 'done');
           localStorage.removeItem('jwtToken')
           dispatch(logout())
        }
     

 
  
 

    
    
    
}

export const getAllTodoList = () => async dispatch =>{

    const {username} = auth

    toast.success("Retrieving Data");
        

    try{
        const res = await trackPromise(axios.post(`${apiUrl}/ticket`,{username}, config))

        if(res.data.invalid_token  ){
            console.log(res.data)
            window.location.replace("/login");

        }
        else if(res.data.data === "No Data Found"){
            toast.error(res.data.data);
            return false

        }
       
        dispatch({
            type: FETCH_TODO,
            payload: res.data
         });
        } catch (error){

            return "Internal server error"
        }


}